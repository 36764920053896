<template>
 <PerformanceChartWrapper>
  <sdCards v-if="performanceState" title="Sales overzicht">
   <Pstates>
    <div
     class="growth-upward active"
     role="div"
    >
     <p>Bruto <small>deze maand</small></p>
     <h1>
      {{ chart.currentMonthCount.gross }}
      <!--      <sub>-->
      <!--       <span> <sdFeatherIcons size="14" type="arrow-up"/> 25% </span>-->
      <!--      </sub>-->
     </h1>
    </div>
    <div
     class="growth-upward"
     role="div"
    >
     <p>Netto <small>deze maand</small></p>
     <sdHeading as="h1">
      {{ kFormatter(chart.currentMonthCount.net) }}
      <!--      <sub>-->
      <!--       <span> <sdFeatherIcons size="14" type="arrow-up"/> 47% </span>-->
      <!--      </sub>-->
     </sdHeading>
    </div>
    <div
     class="growth-upward active"
     role="div"
    >
     <p>Geannuleerd <small>deze maand</small></p>
     <sdHeading as="h1">
      {{ kFormatter(chart.currentMonthCount.cancelled) }}
      <!--      <sub>-->
      <!--       <span> <sdFeatherIcons size="14" type="arrow-up"/> 47% </span>-->
      <!--      </sub>-->
     </sdHeading>
    </div>
   </Pstates>

   <div v-if="preIsLoading" class="sd-spin">
    <a-spin/>
   </div>
   <div v-else class="performance-lineChart">
    <sdChartContainer class="parentContainer">
     <Chartjs
      id="performance"
      :key="chart"
      :datasets="performanceDatasets"
      :height="height"
      :labels="[ 'jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec' ]"
      :options="chartOptions"
      class="performance"
      type="line"
     />
    </sdChartContainer>
    <ul v-if="performanceDatasets">
     <li v-for="(item, index) in performanceDatasets" :key="index + 1" class="custom-label">
            <span
             :style="{
                backgroundColor: item.borderColor,
              }"
            />
      {{ item.label }}
     </li>
    </ul>
   </div>
  </sdCards>
 </PerformanceChartWrapper>
</template>
<script>
import {PerformanceChartWrapper, Pstates} from '../../style';
import {chartLinearGradient, customTooltips} from '../../../../components/utilities/utilities';
import Chartjs from '../../../../components/utilities/chartjs';
import {useStore} from 'vuex';
import {computed, defineComponent, onMounted, ref, toRefs} from 'vue';
import VueTypes from 'vue-types';
import {kFormatter} from "@/utility/utility";

const AverageSalesRevenue = defineComponent({
 name: 'AverageSalesRevenue',
 components: {PerformanceChartWrapper, Pstates, Chartjs},
 props: {
  chartHeights: VueTypes.number.def(100),
  chart: VueTypes.object.def({
   chartData: {
    gross: [],
    net: [],
    cancelled: [],
   },
   currentMonthCount: {
    gross: 0,
    net: 0,
    cancelled: 0,
   }
  }),
 },
 methods: {
  kFormatter,
 },
 setup(props) {
  const {chartHeights} = toRefs(props);
  const {state, dispatch} = useStore();

  const performanceState = computed(() => state.chartContent.performanceData);
  const preIsLoading = computed(() => state.chartContent.perLoading);
  const performance = ref('year');
  const performanceTab = ref('users');

  const height = ref(window.innerWidth <= 575 ? 200 : chartHeights);

  onMounted(() => dispatch('performanceGetData'));
  const handleActiveChangePerformance = (event, value) => {
   event.preventDefault();
   performance.value = value;
   dispatch('performanceFilterData', value);
  };

  const onPerformanceTab = (event, value) => {
   event.preventDefault();
   performanceTab.value = value;
   return dispatch('setIsLoading');
  };

  const performanceDatasets = computed(() =>
   performanceState.value
    ? [
     {
      data: props.chart.chartData.total,
      borderColor: '#000000',
      borderWidth: 2,
      fill: true,
      backgroundColor: () =>
       chartLinearGradient(document.getElementById('performance'), 300, {
        start: '#00000030',
        end: '#ffffff05',
       }),
      label: 'Totaal',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#000000',
      hoverBorderWidth: 5,
     },
     {
      data: props.chart.chartData.net,
      borderColor: '#20c997',
      borderWidth: 2,
      fill: false,
      backgroundColor: '#20c997',
      label: 'Netto',
      // borderDash: [3, 3],
      pointRadius: '0',
      hoverRadius: '0',
     },
     {
      data: props.chart.chartData.gross,
      borderColor: '#0d79df',
      borderWidth: 2,
      fill: false,
      backgroundColor: '#0d79df',
      label: 'Bruto',
      // borderDash: [3, 3],
      pointRadius: '0',
      hoverRadius: '0',
     },
     {
      data: props.chart.chartData.cancelled,
      borderColor: '#E30D0F',
      borderWidth: 2,
      fill: false,
      backgroundColor: '#E30D0F',
      label: 'Geannuleerd',
      // borderDash: [3, 3],
      pointRadius: '0',
      hoverRadius: '0',
     },
    ]
    : [],
  );
  const chartOptions = {
   maintainAspectRatio: true,
   elements: {
    z: 9999,
   },
   legend: {
    display: false,
   },
   hover: {
    mode: 'index',
    intersect: false,
   },
   tooltips: {
    mode: 'label',
    intersect: false,
    backgroundColor: '#ffffff',
    position: 'average',
    enabled: false,
    custom: customTooltips,
    callbacks: {
     title() {
      return 'Orders';
     },
     label(t, d) {
      const {yLabel, datasetIndex} = t;
      return `<span class="chart-data">${yLabel}</span> <span class="data-label">${d.datasets[datasetIndex].label}</span>`;
     },
    },
   },
   scales: {
    yAxes: [
     {
      gridLines: {
       color: '#e5e9f2',
       borderDash: [3, 3],
       zeroLineColor: '#e5e9f2',
       zeroLineWidth: 1,
       zeroLineBorderDash: [3, 3],
      },
      ticks: {
       beginAtZero: true,
       fontSize: 13,
       fontColor: '#182b49',
       // stepSize: 20,
       callback(label) {
        return `${label}`;
       },
      },
     },
    ],
    xAxes: [
     {
      gridLines: {
       display: true,
       zeroLineWidth: 2,
       zeroLineColor: 'transparent',
       color: 'transparent',
       z: 1,
       tickMarkLength: 0,
      },
      ticks: {
       padding: 10,
      },
     },
    ],
   },
  };
  return {
   performanceDatasets,
   onPerformanceTab,
   handleActiveChangePerformance,
   preIsLoading,
   performance,
   performanceTab,
   performanceState,
   height,
   chartOptions,
  };
 },
});

export default AverageSalesRevenue;
</script>
